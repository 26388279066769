import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { X, Upload, FileText } from 'lucide-react';
import Papa from 'papaparse';
import { supabase } from '../lib/supabase';
import type { Tables } from '../lib/supabase';

type JobImportProps = {
  businessId: string;
  onClose: () => void;
  onSuccess: (jobs: Tables['jobs']['Row'][]) => void;
};

type JobData = {
  title: string;
  description: string;
  job_type: 'full_time' | 'part_time' | 'contract' | 'casual';
  location: string;
  salary_range_min?: number;
  salary_range_max?: number;
  requirements: string;
};

export default function JobImport({ businessId, onClose, onSuccess }: JobImportProps) {
  const [importing, setImporting] = useState(false);
  const [preview, setPreview] = useState<JobData[]>([]);
  const { register, handleSubmit } = useForm();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const jobData = results.data.map((row: any) => ({
          title: row.title || '',
          description: row.description || '',
          job_type: row.job_type || 'full_time',
          location: row.location || '',
          salary_range_min: parseInt(row.salary_range_min) || undefined,
          salary_range_max: parseInt(row.salary_range_max) || undefined,
          requirements: row.requirements || '',
        }));
        setPreview(jobData);
      },
    });
  };

  const importJobs = async () => {
    try {
      setImporting(true);
      const jobs = preview.map(job => ({
        business_id: businessId,
        title: job.title,
        description: job.description,
        job_type: job.job_type,
        location: job.location,
        salary_range_min: job.salary_range_min,
        salary_range_max: job.salary_range_max,
        requirements: job.requirements.split('\n').filter(Boolean),
        is_active: true,
      }));

      const { data, error } = await supabase
        .from('jobs')
        .insert(jobs)
        .select();

      if (error) throw error;
      onSuccess(data);
      onClose();
    } catch (error) {
      console.error('Error importing jobs:', error);
    } finally {
      setImporting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full mx-4">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">Import Jobs</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="p-6">
          <div className="mb-6">
            <h3 className="text-lg font-medium mb-2">Upload CSV File</h3>
            <p className="text-gray-600 mb-4">
              Upload a CSV file with job listings. The file should include columns for: title, description,
              job_type, location, salary_range_min, salary_range_max, and requirements.
            </p>
            
            <div className="flex items-center justify-center w-full">
              <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <Upload className="w-10 h-10 mb-3 text-gray-400" />
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="font-semibold">Click to upload</span> or drag and drop
                  </p>
                  <p className="text-xs text-gray-500">CSV files only</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  accept=".csv"
                  onChange={handleFileUpload}
                />
              </label>
            </div>
          </div>

          {preview.length > 0 && (
            <div>
              <h3 className="text-lg font-medium mb-4">Preview ({preview.length} jobs)</h3>
              <div className="max-h-96 overflow-y-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Title
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Location
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Salary Range
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {preview.map((job, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">{job.title}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">{job.location}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {job.job_type.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {job.salary_range_min && job.salary_range_max
                              ? `$${job.salary_range_min.toLocaleString()} - $${job.salary_range_max.toLocaleString()}`
                              : 'Not specified'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-end space-x-4 p-6 border-t bg-gray-50">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={importJobs}
            disabled={importing || preview.length === 0}
            className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
          >
            {importing ? 'Importing...' : 'Import Jobs'}
          </button>
        </div>
      </div>
    </div>
  );
}