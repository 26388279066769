import React, { useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import { Building2, Briefcase, Users, Settings, Plus, Upload, AlertTriangle } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import type { Tables } from '../lib/supabase';
import JobForm from '../components/JobForm';
import JobImport from '../components/JobImport';
import ProfileForm from './ProfileForm';
import toast from 'react-hot-toast';

type Business = Tables['businesses']['Row'];
type Job = Tables['jobs']['Row'];

export default function Dashboard() {
  const { user, profile } = useAuth();
  const [business, setBusiness] = useState<Business | null>(null);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [showJobForm, setShowJobForm] = useState(false);
  const [showJobImport, setShowJobImport] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function loadDashboardData() {
      try {
        if (!user) {
          navigate('/');
          return;
        }

        // Check profile completion
        if (!profile?.full_name || !profile?.phone) {
          toast.error('Please complete your profile first');
          navigate('/dashboard/profile');
          return;
        }

        // Load business data if user is a business owner
        if (profile?.is_business_owner) {
          const { data: businessData, error: businessError } = await supabase
            .from('businesses')
            .select('*')
            .eq('owner_id', user.id)
            .single();

          if (businessError) {
            if (businessError.code === 'PGRST116') {
              // No business found - redirect to create business form
              navigate('/dashboard/business/create');
              return;
            } else {
              throw businessError;
            }
          }

          if (businessData) {
            setBusiness(businessData);
            // Load jobs for the business
            const { data: jobsData, error: jobsError } = await supabase
              .from('jobs')
              .select('*')
              .eq('business_id', businessData.id)
              .order('created_at', { ascending: false });

            if (jobsError) throw jobsError;
            setJobs(jobsData || []);
          }
        }
      } catch (error) {
        console.error('Error loading dashboard data:', error);
        toast.error('Failed to load dashboard data');
      } finally {
        setLoading(false);
      }
    }

    loadDashboardData();
  }, [user, profile, navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-700"></div>
      </div>
    );
  }

  if (!user || !profile) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-gray-700 mb-2">Please Sign In</h2>
        <p className="text-gray-500">You need to be signed in to access the dashboard.</p>
      </div>
    );
  }

  // Show profile completion warning if needed
  if (!profile.full_name || !profile.phone) {
    return (
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6">
        <div className="flex items-start">
          <AlertTriangle className="h-6 w-6 text-yellow-400 mt-0.5 mr-3" />
          <div>
            <h3 className="text-lg font-medium text-yellow-800">Complete Your Profile</h3>
            <p className="mt-2 text-yellow-700">
              Please complete your profile information to access all features.
            </p>
            <Link
              to="/dashboard/profile"
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              Complete Profile
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid md:grid-cols-4 gap-8">
      {/* Sidebar */}
      <div className="md:col-span-1">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="space-y-1">
            <Link
              to="/dashboard"
              className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-50"
            >
              <Building2 className="w-5 h-5 text-gray-600" />
              <span>Business Profile</span>
            </Link>
            <Link
              to="/dashboard/jobs"
              className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-50"
            >
              <Briefcase className="w-5 h-5 text-gray-600" />
              <span>Job Listings</span>
            </Link>
            <Link
              to="/dashboard/applications"
              className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-50"
            >
              <Users className="w-5 h-5 text-gray-600" />
              <span>Applications</span>
            </Link>
            <Link
              to="/dashboard/settings"
              className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-50"
            >
              <Settings className="w-5 h-5 text-gray-600" />
              <span>Settings</span>
            </Link>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="md:col-span-3">
        <Routes>
          <Route index element={
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-2xl font-bold mb-6">Welcome, {profile.full_name}</h2>
              
              {profile.is_business_owner && !business && (
                <div className="bg-green-50 border border-green-200 rounded-lg p-6 mb-6">
                  <h3 className="text-lg font-semibold text-green-800 mb-2">
                    Complete Your Business Profile
                  </h3>
                  <p className="text-green-700 mb-4">
                    Set up your business profile to start posting jobs and connecting with potential employees.
                  </p>
                  <button 
                    onClick={() => navigate('/dashboard/business/create')}
                    className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
                  >
                    Create Business Profile
                  </button>
                </div>
              )}

              {business && (
                <div className="space-y-6">
                  <div className="grid md:grid-cols-3 gap-4">
                    <div className="bg-blue-50 rounded-lg p-6">
                      <h3 className="text-lg font-semibold mb-2">Active Jobs</h3>
                      <p className="text-3xl font-bold text-blue-600">
                        {jobs.filter(job => job.is_active).length}
                      </p>
                    </div>
                    <div className="bg-green-50 rounded-lg p-6">
                      <h3 className="text-lg font-semibold mb-2">Total Applications</h3>
                      <p className="text-3xl font-bold text-green-600">0</p>
                    </div>
                    <div className="bg-purple-50 rounded-lg p-6">
                      <h3 className="text-lg font-semibold mb-2">Profile Views</h3>
                      <p className="text-3xl font-bold text-purple-600">0</p>
                    </div>
                  </div>

                  <div className="bg-gray-50 rounded-lg p-6">
                    <div className="flex items-center justify-between mb-4">
                      <h3 className="text-lg font-semibold">Recent Job Listings</h3>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => setShowJobImport(true)}
                          className="flex items-center space-x-2 bg-white text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-50 border border-gray-300"
                        >
                          <Upload className="w-4 h-4" />
                          <span>Import Jobs</span>
                        </button>
                        <button
                          onClick={() => setShowJobForm(true)}
                          className="flex items-center space-x-2 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
                        >
                          <Plus className="w-4 h-4" />
                          <span>Post a Job</span>
                        </button>
                      </div>
                    </div>
                    {jobs.length > 0 ? (
                      <div className="space-y-4">
                        {jobs.slice(0, 3).map((job) => (
                          <div key={job.id} className="flex items-center justify-between p-4 bg-white rounded-lg shadow">
                            <div>
                              <h4 className="font-semibold">{job.title}</h4>
                              <p className="text-sm text-gray-600">{job.location}</p>
                            </div>
                            <div className="flex items-center space-x-2">
                              <span className={`px-2 py-1 rounded text-sm ${
                                job.is_active ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                              }`}>
                                {job.is_active ? 'Active' : 'Inactive'}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-gray-600">No job listings yet. Create your first job posting!</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          } />
          <Route path="jobs" element={
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-2xl font-bold">Job Listings</h2>
                <div className="flex space-x-2">
                  <button
                    onClick={() => setShowJobImport(true)}
                    className="flex items-center space-x-2 bg-white text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-50 border border-gray-300"
                  >
                    <Upload className="w-4 h-4" />
                    <span>Import Jobs</span>
                  </button>
                  <button
                    onClick={() => setShowJobForm(true)}
                    className="flex items-center space-x-2 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
                  >
                    <Plus className="w-4 h-4" />
                    <span>Post a Job</span>
                  </button>
                </div>
              </div>

              {jobs.length > 0 ? (
                <div className="space-y-4">
                  {jobs.map((job) => (
                    <div key={job.id} className="flex items-center justify-between p-6 bg-white rounded-lg shadow">
                      <div>
                        <h3 className="text-lg font-semibold mb-2">{job.title}</h3>
                        <div className="flex items-center space-x-4 text-sm text-gray-600">
                          <span>{job.location}</span>
                          <span>•</span>
                          <span>{job.job_type.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}</span>
                          {job.salary_range_min && job.salary_range_max && (
                            <>
                              <span>•</span>
                              <span>${job.salary_range_min.toLocaleString()} - ${job.salary_range_max.toLocaleString()}</span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <span className={`px-3 py-1 rounded-full text-sm ${
                          job.is_active ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                        }`}>
                          {job.is_active ? 'Active' : 'Inactive'}
                        </span>
                        <button className="text-gray-600 hover:text-gray-900">
                          <Settings className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-12">
                  <h3 className="text-xl font-semibold text-gray-600 mb-2">No Job Listings</h3>
                  <p className="text-gray-500">Create your first job posting to start finding employees.</p>
                </div>
              )}
            </div>
          } />
          <Route path="applications" element={
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-2xl font-bold mb-6">Job Applications</h2>
              <p className="text-gray-600">No applications yet.</p>
            </div>
          } />
          <Route path="settings" element={
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-2xl font-bold mb-6">Account Settings</h2>
              <div className="space-y-6">
                <div>
                  <h3 className="text-lg font-semibold mb-4">Profile Information</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Full Name</label>
                      <p className="mt-1 text-gray-900">{profile.full_name}</p>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Email</label>
                      <p className="mt-1 text-gray-900">{user.email}</p>
                    </div>
                  </div>
                </div>
                
                {business && (
                  <div>
                    <h3 className="text-lg font-semibold mb-4">Business Information</h3>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Business Name</label>
                        <p className="mt-1 text-gray-900">{business.name}</p>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Location</label>
                        <p className="mt-1 text-gray-900">{business.suburb}, {business.state}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          } />
        </Routes>
      </div>

      {showJobForm && business && (
        <JobForm
          businessId={business.id}
          onClose={() => setShowJobForm(false)}
          onSuccess={(newJob) => {
            setJobs([newJob, ...jobs]);
            setShowJobForm(false);
          }}
        />
      )}

      {showJobImport && business && (
        <JobImport
          businessId={business.id}
          onClose={() => setShowJobImport(false)}
          onSuccess={(newJobs) => {
            setJobs([...newJobs, ...jobs]);
            setShowJobImport(false);
          }}
        />
      )}
    </div>
  );
}