import { supabase } from './supabase';
import { toast } from 'react-hot-toast';
import type { Tables } from './supabase';

export type SignInCredentials = {
  email: string;
  password: string;
};

export type SignUpCredentials = SignInCredentials & {
  fullName: string;
  isBusinessOwner?: boolean;
};

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

async function retryOperation<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES,
  delay = RETRY_DELAY
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0) {
      console.log(`Retrying operation (${MAX_RETRIES - retries + 1}/${MAX_RETRIES})...`);
      await new Promise(resolve => setTimeout(resolve, delay));
      return retryOperation(operation, retries - 1, delay * 2);
    }
    throw error;
  }
}

export async function signIn({ email, password }: SignInCredentials) {
  try {
    console.log('Attempting sign in for:', email);
    
    // Validate input
    if (!email || !password) {
      throw new Error('Email and password are required');
    }

    if (!email.includes('@')) {
      throw new Error('Invalid email format');
    }

    const signInOperation = async () => {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
        options: {
          redirectTo: `${window.location.origin}/auth/callback`
        }
      });

      if (error) throw error;
      if (!data?.user) throw new Error('No user returned from sign in');
      return data.user;
    };

    const user = await retryOperation(signInOperation);
    console.log('Sign in successful');
    toast.success('Welcome back!');
    return user;
  } catch (error: any) {
    console.error('Sign in process error:', error);
    
    // Handle specific error cases
    if (error.message.includes('Invalid login credentials')) {
      toast.error('Invalid email or password');
    } else if (error.message.includes('rate limit')) {
      toast.error('Too many attempts. Please try again later.');
    } else if (error.message.includes('network')) {
      toast.error('Network error. Please check your connection.');
    } else {
      toast.error('Failed to sign in. Please try again.');
    }
    
    throw error;
  }
}

export async function signUp({ email, password, fullName, isBusinessOwner = false }: SignUpCredentials) {
  try {
    console.log('Attempting sign up for:', email);

    // Validate input
    if (!email || !password || !fullName) {
      throw new Error('All fields are required');
    }

    if (password.length < 8) {
      throw new Error('Password must be at least 8 characters');
    }

    const signUpOperation = async () => {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            full_name: fullName,
            is_business_owner: isBusinessOwner
          },
          emailRedirectTo: `${window.location.origin}/auth/callback`
        }
      });

      if (error) throw error;
      if (!data?.user) throw new Error('No user returned from sign up');

      // Create profile immediately
      const { error: profileError } = await supabase
        .from('profiles')
        .insert([{
          id: data.user.id,
          full_name: fullName,
          is_business_owner: isBusinessOwner,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }]);

      if (profileError) throw profileError;
      return data.user;
    };

    const user = await retryOperation(signUpOperation);
    console.log('Sign up successful');
    toast.success('Account created successfully! Please check your email to verify your account.');
    return user;
  } catch (error: any) {
    console.error('Sign up process error:', error);
    
    // Handle specific error cases
    if (error.message.includes('already registered')) {
      toast.error('This email is already registered');
    } else if (error.message.includes('rate limit')) {
      toast.error('Too many attempts. Please try again later.');
    } else if (error.message.includes('network')) {
      toast.error('Network error. Please check your connection.');
    } else {
      toast.error('Failed to create account. Please try again.');
    }
    
    throw error;
  }
}

export async function signOut() {
  try {
    console.log('Attempting sign out');
    const signOutOperation = async () => {
      const { error } = await supabase.auth.signOut({
        scope: 'local'
      });
      
      if (error) throw error;
    };

    await retryOperation(signOutOperation);
    console.log('Sign out successful');
    toast.success('Signed out successfully');
  } catch (error: any) {
    console.error('Sign out process error:', error);
    
    // Handle specific error cases
    if (error.message.includes('network')) {
      toast.error('Network error. Please check your connection.');
    } else {
      toast.error('Failed to sign out. Please try again.');
    }
    
    throw error;
  }
}