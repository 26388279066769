import React from 'react';
import { useForm } from 'react-hook-form';
import { X } from 'lucide-react';
import { supabase } from '../lib/supabase';
import type { Tables } from '../lib/supabase';

type Job = Tables['jobs']['Row'];

type JobFormProps = {
  businessId: string;
  onClose: () => void;
  onSuccess: (job: Job) => void;
};

type JobFormData = {
  title: string;
  description: string;
  job_type: 'full_time' | 'part_time' | 'contract' | 'casual';
  location: string;
  salary_range_min?: number;
  salary_range_max?: number;
  requirements: string;
};

export default function JobForm({ businessId, onClose, onSuccess }: JobFormProps) {
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<JobFormData>();

  const onSubmit = async (data: JobFormData) => {
    try {
      const { data: job, error } = await supabase
        .from('jobs')
        .insert([{
          business_id: businessId,
          title: data.title,
          description: data.description,
          job_type: data.job_type,
          location: data.location,
          salary_range_min: data.salary_range_min,
          salary_range_max: data.salary_range_max,
          requirements: data.requirements.split('\n').filter(Boolean),
          is_active: true,
        }])
        .select()
        .single();

      if (error) throw error;
      onSuccess(job);
    } catch (error) {
      console.error('Error creating job:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full mx-4">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">Post a New Job</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Job Title
            </label>
            <input
              type="text"
              id="title"
              {...register('title', { required: 'Job title is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
            />
            {errors.title && (
              <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
            )}
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Job Description
            </label>
            <textarea
              id="description"
              rows={4}
              {...register('description', { required: 'Job description is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
            />
            {errors.description && (
              <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="job_type" className="block text-sm font-medium text-gray-700">
                Job Type
              </label>
              <select
                id="job_type"
                {...register('job_type', { required: 'Job type is required' })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              >
                <option value="full_time">Full Time</option>
                <option value="part_time">Part Time</option>
                <option value="contract">Contract</option>
                <option value="casual">Casual</option>
              </select>
              {errors.job_type && (
                <p className="mt-1 text-sm text-red-600">{errors.job_type.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                Location
              </label>
              <input
                type="text"
                id="location"
                {...register('location', { required: 'Location is required' })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              />
              {errors.location && (
                <p className="mt-1 text-sm text-red-600">{errors.location.message}</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="salary_range_min" className="block text-sm font-medium text-gray-700">
                Minimum Salary (optional)
              </label>
              <input
                type="number"
                id="salary_range_min"
                {...register('salary_range_min', { min: 0 })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              />
            </div>

            <div>
              <label htmlFor="salary_range_max" className="block text-sm font-medium text-gray-700">
                Maximum Salary (optional)
              </label>
              <input
                type="number"
                id="salary_range_max"
                {...register('salary_range_max', { min: 0 })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              />
            </div>
          </div>

          <div>
            <label htmlFor="requirements" className="block text-sm font-medium text-gray-700">
              Requirements (one per line)
            </label>
            <textarea
              id="requirements"
              rows={4}
              {...register('requirements', { required: 'Requirements are required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              placeholder="- Minimum 3 years of landscaping experience&#10;- Valid driver's license&#10;- Physical fitness and ability to work outdoors"
            />
            {errors.requirements && (
              <p className="mt-1 text-sm text-red-600">{errors.requirements.message}</p>
            )}
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
            >
              {isSubmitting ? 'Creating...' : 'Create Job'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}