import { createClient } from '@supabase/supabase-js';
import type { Database } from '../types/supabase';
import toast from 'react-hot-toast';

// Validate environment variables
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  const error = 'Missing Supabase configuration. Please check your environment variables.';
  toast.error(error);
  throw new Error(error);
}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'pkce',
    storage: localStorage,
    storageKey: 'aussie-landscaping-auth',
    debug: import.meta.env.DEV
  },
  db: {
    schema: 'public'
  },
  global: {
    headers: {
      'x-application-name': 'aussie-landscaping-directory'
    }
  }
});

// Add error handling for network issues
supabase.auth.onAuthStateChange((event, session) => {
  if (event === 'SIGNED_OUT') {
    // Clear any cached data
    localStorage.removeItem('aussie-landscaping-cache');
  }
});

// Export types
export type Tables = Database['public']['Tables'];
export type Enums = Database['public']['Enums'];

// Add error monitoring
window.addEventListener('unhandledrejection', (event) => {
  if (event.reason?.message?.includes('Failed to fetch')) {
    toast.error('Network error. Please check your connection.');
  }
});