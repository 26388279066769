import React from 'react';
import { useForm } from 'react-hook-form';
import { X, Check, CreditCard } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import toast from 'react-hot-toast';

type SubscriptionModalProps = {
  businessId: string;
  onClose: () => void;
  onSuccess: () => void;
};

type PaymentFormData = {
  cardNumber: string;
  expiryDate: string;
  cvc: string;
  name: string;
};

const SUBSCRIPTION_PRICE = 299; // AUD

export default function SubscriptionModal({ businessId, onClose, onSuccess }: SubscriptionModalProps) {
  const { user } = useAuth();
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<PaymentFormData>();

  const features = [
    'Verified Business Badge',
    'Priority Listing in Search Results',
    'Unlimited Job Postings',
    'Portfolio Gallery',
    'Quote Request System',
    'Business Analytics',
    'Customer Management Tools',
    'Email Support'
  ];

  const onSubmit = async (data: PaymentFormData) => {
    if (!user) return;

    try {
      // In a real implementation, this would integrate with a payment processor
      // like Stripe to handle the actual payment

      // For now, we'll just create a subscription record
      const startDate = new Date();
      const endDate = new Date();
      endDate.setFullYear(endDate.getFullYear() + 1);

      const { data: subscription, error: subscriptionError } = await supabase
        .from('subscriptions')
        .insert([{
          business_id: businessId,
          status: 'active',
          starts_at: startDate.toISOString(),
          ends_at: endDate.toISOString(),
          auto_renew: true
        }])
        .select()
        .single();

      if (subscriptionError) throw subscriptionError;

      // Record the payment
      const { error: paymentError } = await supabase
        .from('payments')
        .insert([{
          subscription_id: subscription.id,
          amount: SUBSCRIPTION_PRICE,
          currency: 'AUD',
          status: 'completed',
          payment_method: 'credit_card'
        }]);

      if (paymentError) throw paymentError;

      // Update business verification status
      const { error: businessError } = await supabase
        .from('businesses')
        .update({ is_verified: true })
        .eq('id', businessId);

      if (businessError) throw businessError;
      
      toast.success('Subscription activated successfully!');
      onSuccess();
      onClose();
    } catch (error) {
      console.error('Error processing subscription:', error);
      toast.error('Failed to process subscription');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full mx-4">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">Business Subscription</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="grid md:grid-cols-2 gap-8 p-6">
          <div>
            <div className="bg-green-50 rounded-lg p-6">
              <h3 className="text-2xl font-bold text-green-800 mb-2">
                Premium Business Package
              </h3>
              <div className="flex items-baseline mb-4">
                <span className="text-4xl font-bold text-green-600">${SUBSCRIPTION_PRICE}</span>
                <span className="text-gray-600 ml-2">/ year</span>
              </div>
              <ul className="space-y-3">
                {features.map((feature, index) => (
                  <li key={index} className="flex items-center text-green-700">
                    <Check className="w-5 h-5 mr-2 flex-shrink-0" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Cardholder Name
                </label>
                <input
                  type="text"
                  id="name"
                  {...register('name', { required: 'Name is required' })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
                {errors.name && (
                  <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
                )}
              </div>

              <div>
                <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-700">
                  Card Number
                </label>
                <div className="mt-1 relative">
                  <input
                    type="text"
                    id="cardNumber"
                    {...register('cardNumber', {
                      required: 'Card number is required',
                      pattern: {
                        value: /^[0-9]{16}$/,
                        message: 'Invalid card number'
                      }
                    })}
                    className="block w-full rounded-md border-gray-300 pl-10 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="1234 5678 9012 3456"
                  />
                  <CreditCard className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                </div>
                {errors.cardNumber && (
                  <p className="mt-1 text-sm text-red-600">{errors.cardNumber.message}</p>
                )}
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-700">
                    Expiry Date
                  </label>
                  <input
                    type="text"
                    id="expiryDate"
                    {...register('expiryDate', {
                      required: 'Expiry date is required',
                      pattern: {
                        value: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
                        message: 'Invalid format (MM/YY)'
                      }
                    })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="MM/YY"
                  />
                  {errors.expiryDate && (
                    <p className="mt-1 text-sm text-red-600">{errors.expiryDate.message}</p>
                  )}
                </div>

                <div>
                  <label htmlFor="cvc" className="block text-sm font-medium text-gray-700">
                    CVC
                  </label>
                  <input
                    type="text"
                    id="cvc"
                    {...register('cvc', {
                      required: 'CVC is required',
                      pattern: {
                        value: /^[0-9]{3,4}$/,
                        message: 'Invalid CVC'
                      }
                    })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="123"
                  />
                  {errors.cvc && (
                    <p className="mt-1 text-sm text-red-600">{errors.cvc.message}</p>
                  )}
                </div>
              </div>

              <div className="mt-6">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                >
                  {isSubmitting ? 'Processing...' : `Subscribe for $${SUBSCRIPTION_PRICE}`}
                </button>
              </div>

              <p className="text-xs text-gray-500 text-center mt-4">
                By subscribing, you agree to our terms of service and privacy policy.
                Your subscription will automatically renew annually.
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}