import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Award as Garden, Briefcase, Search, User, LogOut, MapPin } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import AuthModal from './AuthModal';
import { signOut } from '../lib/auth';

export default function Navbar() {
  const { user, profile, loading } = useAuth();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authMode, setAuthMode] = useState<'signin' | 'signup'>('signin');
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut();
      setShowDropdown(false);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <>
      <nav className="bg-green-700 text-white shadow-lg">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <Link to="/" className="flex items-center space-x-2">
              <Garden className="w-8 h-8" />
              <span className="text-xl font-bold">AussieScapes</span>
            </Link>
            
            <div className="hidden md:flex items-center space-x-8">
              <Link to="/directory" className="flex items-center space-x-1 hover:text-green-200">
                <Search className="w-5 h-5" />
                <span>Find Landscapers</span>
              </Link>
              <Link to="/locations" className="flex items-center space-x-1 hover:text-green-200">
                <MapPin className="w-5 h-5" />
                <span>Browse Locations</span>
              </Link>
              <Link to="/jobs" className="flex items-center space-x-1 hover:text-green-200">
                <Briefcase className="w-5 h-5" />
                <span>Jobs</span>
              </Link>
              
              {loading ? (
                <div className="w-8 h-8 rounded-full border-2 border-white border-t-transparent animate-spin" />
              ) : user ? (
                <div className="relative" ref={dropdownRef}>
                  <button 
                    className="flex items-center space-x-2 hover:text-green-200"
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    <User className="w-5 h-5" />
                    <span>{profile?.full_name || 'Account'}</span>
                  </button>
                  {showDropdown && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                      <Link
                        to="/dashboard"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setShowDropdown(false)}
                      >
                        Dashboard
                      </Link>
                      <button
                        onClick={handleSignOut}
                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        <div className="flex items-center space-x-2">
                          <LogOut className="w-4 h-4" />
                          <span>Sign Out</span>
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="space-x-4">
                  <button
                    onClick={() => {
                      setAuthMode('signin');
                      setShowAuthModal(true);
                    }}
                    className="text-white hover:text-green-200"
                  >
                    Sign In
                  </button>
                  <button
                    onClick={() => {
                      setAuthMode('signup');
                      setShowAuthModal(true);
                    }}
                    className="bg-white text-green-700 px-4 py-2 rounded-lg font-medium hover:bg-green-50"
                  >
                    List Your Business
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        mode={authMode}
      />
    </>
  );
}