import React from 'react';
import { Search, MapPin, Star, Users } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <div className="space-y-16">
      {/* Hero Section */}
      <section className="relative h-[600px] -mt-8">
        <div className="absolute inset-0 bg-cover bg-center" style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1558904541-efa843a96f01?auto=format&fit=crop&q=80")',
          backgroundBlendMode: 'overlay',
          backgroundColor: 'rgba(0, 0, 0, 0.4)'
        }} />
        
        <div className="relative container mx-auto px-4 h-full flex items-center">
          <div className="max-w-2xl text-white">
            <h1 className="text-5xl font-bold mb-6">Find Top Landscapers in Australia</h1>
            <p className="text-xl mb-8">Connect with professional landscapers and gardeners in your area. Browse reviews, compare quotes, and find the perfect match for your project.</p>
            
            <div className="bg-white rounded-lg p-4 shadow-lg">
              <div className="flex items-center space-x-4">
                <div className="flex-1">
                  <div className="flex items-center space-x-2 text-gray-600">
                    <Search className="w-5 h-5" />
                    <input
                      type="text"
                      placeholder="What service do you need?"
                      className="w-full p-2 focus:outline-none"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex items-center space-x-2 text-gray-600 border-l pl-4">
                    <MapPin className="w-5 h-5" />
                    <input
                      type="text"
                      placeholder="Enter your location"
                      className="w-full p-2 focus:outline-none"
                    />
                  </div>
                </div>
                <button className="bg-green-700 text-white px-8 py-3 rounded-lg hover:bg-green-600">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="container mx-auto px-4">
        <div className="grid md:grid-cols-3 gap-8">
          <div className="text-center p-6">
            <div className="bg-green-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
              <Star className="w-8 h-8 text-green-700" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Verified Professionals</h3>
            <p className="text-gray-600">All businesses are verified and reviewed by real customers</p>
          </div>
          
          <div className="text-center p-6">
            <div className="bg-green-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
              <Users className="w-8 h-8 text-green-700" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Job Opportunities</h3>
            <p className="text-gray-600">Find or post landscaping jobs across Australia</p>
          </div>
          
          <div className="text-center p-6">
            <div className="bg-green-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
              <MapPin className="w-8 h-8 text-green-700" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Local Experts</h3>
            <p className="text-gray-600">Connect with professionals in your area</p>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-green-700 text-white py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">Are you a landscaping professional?</h2>
          <p className="text-xl mb-8">Join our network of trusted professionals and grow your business</p>
          <Link to="/register" className="bg-white text-green-700 px-8 py-3 rounded-lg font-medium hover:bg-green-50">
            List Your Business
          </Link>
        </div>
      </section>
    </div>
  );
}