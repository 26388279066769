import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MapPin, Phone, Globe, Mail, Star, Clock, Award } from 'lucide-react';
import { supabase } from '../lib/supabase';
import type { Tables } from '../lib/supabase';

type Business = Tables['businesses']['Row'];
type Category = Tables['business_categories']['Row'];

type BusinessWithCategories = Business & {
  services: Category[];
};

export default function BusinessProfile() {
  const { slug } = useParams<{ slug: string }>();
  const [business, setBusiness] = useState<BusinessWithCategories | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadBusiness() {
      try {
        const { data, error } = await supabase
          .from('businesses')
          .select(`
            *,
            services:business_services(
              category:business_categories(*)
            )
          `)
          .eq('slug', slug)
          .single();

        if (error) throw error;

        // Transform the data to match our type
        const businessWithCategories: BusinessWithCategories = {
          ...data,
          services: data.services.map((service: any) => service.category),
        };

        setBusiness(businessWithCategories);
      } catch (error) {
        console.error('Error loading business:', error);
      } finally {
        setLoading(false);
      }
    }

    if (slug) {
      loadBusiness();
    }
  }, [slug]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-700"></div>
      </div>
    );
  }

  if (!business) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-gray-700 mb-2">Business Not Found</h2>
        <p className="text-gray-500">The business you're looking for doesn't exist or has been removed.</p>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Hero Section */}
      <div className="relative h-[300px] -mt-8 mb-16">
        <div className="absolute inset-0 bg-cover bg-center" style={{
          backgroundImage: business.cover_image_url
            ? `url(${business.cover_image_url})`
            : 'url(https://images.unsplash.com/photo-1558904541-efa843a96f01?auto=format&fit=crop&q=80)',
          backgroundBlendMode: 'overlay',
          backgroundColor: 'rgba(0, 0, 0, 0.4)'
        }} />
        
        <div className="absolute -bottom-16 left-8">
          <div className="bg-white rounded-lg shadow-lg p-4 flex items-center space-x-4">
            <div className="w-24 h-24 bg-gray-200 rounded-lg overflow-hidden">
              {business.logo_url ? (
                <img src={business.logo_url} alt={business.name} className="w-full h-full object-cover" />
              ) : (
                <div className="w-full h-full flex items-center justify-center bg-green-100">
                  <Award className="w-12 h-12 text-green-600" />
                </div>
              )}
            </div>
            <div>
              <h1 className="text-2xl font-bold mb-2">{business.name}</h1>
              <div className="flex items-center text-gray-600">
                <MapPin className="w-4 h-4 mr-1" />
                <span>{business.suburb}, {business.state}</span>
                {business.is_verified && (
                  <span className="ml-4 flex items-center text-green-600">
                    <Star className="w-4 h-4 mr-1" />
                    <span className="text-sm font-medium">Verified Business</span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-3 gap-8">
        <div className="md:col-span-2 space-y-8">
          {/* About Section */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">About Us</h2>
            <p className="text-gray-600 whitespace-pre-line">{business.description}</p>
          </div>

          {/* Services Section */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Our Services</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {business.services.map((service) => (
                <div key={service.id} className="bg-gray-50 rounded-lg p-4">
                  <h3 className="font-medium mb-2">{service.name}</h3>
                  <p className="text-sm text-gray-600">{service.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Contact Sidebar */}
        <div className="space-y-6">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Contact Information</h2>
            <div className="space-y-4">
              {business.phone && (
                <div className="flex items-center text-gray-600">
                  <Phone className="w-5 h-5 mr-2" />
                  <a href={`tel:${business.phone}`} className="hover:text-green-600">
                    {business.phone}
                  </a>
                </div>
              )}
              {business.email && (
                <div className="flex items-center text-gray-600">
                  <Mail className="w-5 h-5 mr-2" />
                  <a href={`mailto:${business.email}`} className="hover:text-green-600">
                    {business.email}
                  </a>
                </div>
              )}
              {business.website && (
                <div className="flex items-center text-gray-600">
                  <Globe className="w-5 h-5 mr-2" />
                  <a href={business.website} target="_blank" rel="noopener noreferrer" className="hover:text-green-600">
                    Visit Website
                  </a>
                </div>
              )}
              {business.address && (
                <div className="flex items-center text-gray-600">
                  <MapPin className="w-5 h-5 mr-2" />
                  <span>{business.address}</span>
                </div>
              )}
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center text-gray-600 mb-4">
              <Clock className="w-5 h-5 mr-2" />
              <span>Member since {new Date(business.created_at).getFullYear()}</span>
            </div>
            {business.abn && (
              <div className="text-sm text-gray-600">
                <strong>ABN:</strong> {business.abn}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}