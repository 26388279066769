import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin } from 'lucide-react';

const locations = {
  'New South Wales': [
    'Sydney', 'Newcastle', 'Wollongong', 'Central Coast', 'Coffs Harbour',
    'Byron Bay', 'Port Macquarie', 'Albury', 'Wagga Wagga', 'Tamworth'
  ],
  'Victoria': [
    'Melbourne', 'Geelong', 'Ballarat', 'Bendigo', 'Shepparton',
    'Mildura', 'Warrnambool', 'Wodonga', 'Traralgon', 'Horsham'
  ],
  'Queensland': [
    'Brisbane', 'Gold Coast', 'Sunshine Coast', 'Cairns', 'Townsville',
    'Toowoomba', 'Mackay', 'Rockhampton', 'Bundaberg', 'Hervey Bay'
  ],
  'Western Australia': [
    'Perth', 'Mandurah', 'Bunbury', 'Geraldton', 'Kalgoorlie',
    'Albany', 'Broome', 'Port Hedland', 'Karratha', 'Esperance'
  ],
  'South Australia': [
    'Adelaide', 'Mount Gambier', 'Whyalla', 'Port Augusta', 'Port Pirie',
    'Murray Bridge', 'Port Lincoln', 'Victor Harbor', 'Gawler', 'Mount Barker'
  ],
  'Tasmania': [
    'Hobart', 'Launceston', 'Devonport', 'Burnie', 'Ulverstone',
    'Kingston', 'Wynyard', 'New Norfolk', 'Bridgewater', 'Sorell'
  ],
  'Northern Territory': [
    'Darwin', 'Alice Springs', 'Palmerston', 'Katherine', 'Nhulunbuy',
    'Tennant Creek', 'Jabiru', 'Yulara', 'Alyangula', 'Pine Creek'
  ],
  'Australian Capital Territory': [
    'Canberra', 'Belconnen', 'Tuggeranong', 'Gungahlin', 'Woden Valley',
    'Weston Creek', 'Molonglo Valley', 'Hall', 'Tharwa'
  ]
};

export default function LocationDirectory() {
  return (
    <div className="space-y-8">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h1 className="text-3xl font-bold mb-6">Find Landscapers by Location</h1>
        <p className="text-gray-600 mb-4">Browse landscaping professionals in your area across Australia</p>
      </div>

      <div className="grid gap-8">
        {Object.entries(locations).map(([state, cities]) => (
          <div key={state} className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold mb-4 text-green-700">{state}</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {cities.map(city => (
                <Link
                  key={city}
                  to={`/directory/${state.toLowerCase().replace(/\s+/g, '-')}/${city.toLowerCase().replace(/\s+/g, '-')}`}
                  className="flex items-center space-x-2 p-3 rounded-lg hover:bg-green-50 transition-colors"
                >
                  <MapPin className="w-4 h-4 text-green-600" />
                  <span className="text-gray-700 hover:text-green-700">{city}</span>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}