import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MapPin, Star, Phone, Globe } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { format } from 'date-fns';

type Business = {
  id: string;
  name: string;
  slug: string;
  description: string;
  suburb: string;
  state: string;
  phone: string;
  website: string;
  logo_url: string;
  cover_image_url: string;
  is_verified: boolean;
  created_at: string;
};

type Category = {
  id: string;
  name: string;
  slug: string;
};

export default function BusinessDirectory() {
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      try {
        const [businessesResponse, categoriesResponse] = await Promise.all([
          supabase
            .from('businesses')
            .select('*')
            .order('created_at', { ascending: false }),
          supabase
            .from('business_categories')
            .select('*')
            .order('name')
        ]);

        if (businessesResponse.error) throw businessesResponse.error;
        if (categoriesResponse.error) throw categoriesResponse.error;

        setBusinesses(businessesResponse.data);
        setCategories(categoriesResponse.data);
      } catch (error) {
        console.error('Error loading directory data:', error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  const filteredBusinesses = businesses.filter(business => {
    const matchesSearch = business.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         business.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         business.suburb?.toLowerCase().includes(searchQuery.toLowerCase());
    
    if (selectedCategory && !matchesSearch) return false;
    return matchesSearch;
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-700"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h1 className="text-3xl font-bold mb-6">Find Landscaping Professionals</h1>
        
        <div className="grid md:grid-cols-3 gap-4">
          <div>
            <label htmlFor="search" className="block text-sm font-medium text-gray-700 mb-1">
              Search
            </label>
            <input
              type="text"
              id="search"
              placeholder="Search businesses..."
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          
          <div>
            <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
              Category
            </label>
            <select
              id="category"
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">All Categories</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-6">
        {filteredBusinesses.map((business) => (
          <Link
            key={business.id}
            to={`/business/${business.slug}`}
            className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
          >
            <div className="h-48 bg-cover bg-center" style={{
              backgroundImage: business.cover_image_url
                ? `url(${business.cover_image_url})`
                : 'url(https://images.unsplash.com/photo-1558904541-efa843a96f01?auto=format&fit=crop&q=80)'
            }} />
            
            <div className="p-6">
              <div className="flex items-start justify-between">
                <div>
                  <h2 className="text-xl font-semibold mb-2">{business.name}</h2>
                  <div className="flex items-center text-gray-600 mb-2">
                    <MapPin className="w-4 h-4 mr-1" />
                    <span>{business.suburb}, {business.state}</span>
                  </div>
                </div>
                {business.is_verified && (
                  <div className="flex items-center text-green-600">
                    <Star className="w-5 h-5 mr-1" />
                    <span className="text-sm font-medium">Verified</span>
                  </div>
                )}
              </div>
              
              <p className="text-gray-600 mb-4 line-clamp-2">{business.description}</p>
              
              <div className="flex items-center space-x-4 text-sm text-gray-600">
                {business.phone && (
                  <div className="flex items-center">
                    <Phone className="w-4 h-4 mr-1" />
                    <span>{business.phone}</span>
                  </div>
                )}
                {business.website && (
                  <div className="flex items-center">
                    <Globe className="w-4 h-4 mr-1" />
                    <span>Website</span>
                  </div>
                )}
              </div>
            </div>
          </Link>
        ))}
      </div>

      {filteredBusinesses.length === 0 && (
        <div className="text-center py-12">
          <h3 className="text-xl font-semibold text-gray-600 mb-2">No businesses found</h3>
          <p className="text-gray-500">Try adjusting your search criteria</p>
        </div>
      )}
    </div>
  );
}