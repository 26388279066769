import React, { createContext, useContext, useEffect, useState } from 'react';
import { User, AuthError } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase';
import type { Tables } from '../lib/supabase';
import toast from 'react-hot-toast';
import { useNetworkStatus } from '../hooks/useNetworkStatus';

type Profile = Tables['profiles']['Row'];

interface AuthContextType {
  user: User | null;
  profile: Profile | null;
  loading: boolean;
  refreshProfile: () => Promise<void>;
  error: AuthError | null;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  profile: null,
  loading: true,
  refreshProfile: async () => {},
  error: null
});

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AuthError | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const isOnline = useNetworkStatus();

  const loadProfile = async (userId: string) => {
    if (!isOnline) {
      console.log('Offline - using cached profile if available');
      const cachedProfile = localStorage.getItem(`profile-${userId}`);
      if (cachedProfile) {
        setProfile(JSON.parse(cachedProfile));
        return;
      }
    }

    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) {
        if (error.code === 'PGRST116') {
          await createNewProfile(userId);
        } else {
          throw error;
        }
      } else {
        setProfile(data);
        localStorage.setItem(`profile-${userId}`, JSON.stringify(data));
      }
    } catch (error) {
      console.error('Error loading profile:', error);
      if (retryCount < MAX_RETRIES) {
        setRetryCount(prev => prev + 1);
        setTimeout(() => loadProfile(userId), RETRY_DELAY * Math.pow(2, retryCount));
      } else {
        toast.error('Failed to load profile. Please refresh the page.');
        setError(error as AuthError);
      }
    }
  };

  const createNewProfile = async (userId: string) => {
    try {
      const { data: userData } = await supabase.auth.getUser();
      if (!userData.user) throw new Error('No user found');

      const newProfile = {
        id: userId,
        full_name: userData.user.user_metadata.full_name || userData.user.email?.split('@')[0],
        is_business_owner: userData.user.user_metadata.is_business_owner || false,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      const { data, error } = await supabase
        .from('profiles')
        .insert([newProfile])
        .select()
        .single();

      if (error) throw error;
      setProfile(data);
      localStorage.setItem(`profile-${userId}`, JSON.stringify(data));
    } catch (error) {
      console.error('Error creating profile:', error);
      throw error;
    }
  };

  useEffect(() => {
    let mounted = true;

    const initAuth = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) throw error;
        
        if (mounted) {
          if (session?.user) {
            setUser(session.user);
            await loadProfile(session.user.id);
          }
          setLoading(false);
        }
      } catch (error) {
        console.error('Auth initialization error:', error);
        if (mounted) {
          setLoading(false);
          setError(error as AuthError);
        }
      }
    };

    initAuth();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (!mounted) return;

      if (session?.user) {
        setUser(session.user);
        setRetryCount(0);
        await loadProfile(session.user.id);
      } else {
        setUser(null);
        setProfile(null);
      }
      setLoading(false);
    });

    return () => {
      mounted = false;
      subscription.unsubscribe();
    };
  }, [isOnline]);

  return (
    <AuthContext.Provider value={{ user, profile, loading, refreshProfile: () => loadProfile(user?.id || ''), error }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}