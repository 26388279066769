import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import toast from 'react-hot-toast';
import LocationSearch from '../components/LocationSearch';
import SubscriptionModal from '../components/SubscriptionModal';

type BusinessFormData = {
  name: string;
  description: string;
  abn: string;
  phone: string;
  email: string;
  website: string;
};

export default function BusinessForm() {
  const { user, refreshProfile } = useAuth();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<BusinessFormData>();
  const [location, setLocation] = useState<{ address: string; lat: number; lng: number } | null>(null);
  const [showSubscription, setShowSubscription] = useState(false);
  const [createdBusinessId, setCreatedBusinessId] = useState<string | null>(null);

  const onSubmit = async (data: BusinessFormData) => {
    if (!user || !location) return;

    try {
      // Create URL-friendly slug from business name
      const slug = data.name
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)/g, '');

      // Extract suburb and state from location
      const addressParts = location.address.split(', ');
      const suburb = addressParts[0];
      const state = addressParts[1];

      const { data: business, error } = await supabase
        .from('businesses')
        .insert([{
          owner_id: user.id,
          name: data.name,
          slug,
          description: data.description,
          abn: data.abn,
          phone: data.phone,
          email: data.email,
          website: data.website,
          suburb,
          state,
          latitude: location.lat,
          longitude: location.lng,
          is_verified: false
        }])
        .select()
        .single();

      if (error) throw error;

      setCreatedBusinessId(business.id);
      setShowSubscription(true);
    } catch (error) {
      console.error('Error creating business:', error);
      toast.error('Failed to create business profile');
    }
  };

  const handleSubscriptionSuccess = async () => {
    await refreshProfile();
    toast.success('Business profile created and verified successfully!');
    navigate('/dashboard');
  };

  return (
    <div className="max-w-3xl mx-auto">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h1 className="text-2xl font-bold mb-6">Create Business Profile</h1>
        
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Business Name
            </label>
            <input
              type="text"
              id="name"
              {...register('name', { required: 'Business name is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
            />
            {errors.name && (
              <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
            )}
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Business Description
            </label>
            <textarea
              id="description"
              rows={4}
              {...register('description', { required: 'Description is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              placeholder="Tell potential customers about your business, services, and expertise..."
            />
            {errors.description && (
              <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
            )}
          </div>

          <div>
            <label htmlFor="location" className="block text-sm font-medium text-gray-700">
              Business Location
            </label>
            <LocationSearch
              onSelect={(loc) => setLocation(loc)}
            />
            {!location && (
              <p className="mt-1 text-sm text-red-600">Location is required</p>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="abn" className="block text-sm font-medium text-gray-700">
                ABN
              </label>
              <input
                type="text"
                id="abn"
                {...register('abn', { required: 'ABN is required' })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                placeholder="XX XXX XXX XXX"
              />
              {errors.abn && (
                <p className="mt-1 text-sm text-red-600">{errors.abn.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                {...register('phone', { required: 'Phone number is required' })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                placeholder="04XX XXX XXX"
              />
              {errors.phone && (
                <p className="mt-1 text-sm text-red-600">{errors.phone.message}</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Business Email
              </label>
              <input
                type="email"
                id="email"
                {...register('email', { required: 'Email is required' })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                placeholder="business@example.com"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="website" className="block text-sm font-medium text-gray-700">
                Website (optional)
              </label>
              <input
                type="url"
                id="website"
                {...register('website')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                placeholder="https://www.example.com"
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate('/dashboard')}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting || !location}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
            >
              {isSubmitting ? 'Creating...' : 'Continue to Subscription'}
            </button>
          </div>
        </form>
      </div>

      {showSubscription && createdBusinessId && (
        <SubscriptionModal
          businessId={createdBusinessId}
          onClose={() => {
            setShowSubscription(false);
            navigate('/dashboard');
          }}
          onSuccess={handleSubscriptionSuccess}
        />
      )}
    </div>
  );
}