import React, { useEffect, useState } from 'react';
import { Briefcase, MapPin, Building2, Clock } from 'lucide-react';
import { supabase } from '../lib/supabase';
import type { Tables } from '../lib/supabase';

type Job = Tables['jobs']['Row'];
type Business = Tables['businesses']['Row'];

type JobWithBusiness = Job & {
  business: Business;
};

export default function JobBoard() {
  const [jobs, setJobs] = useState<JobWithBusiness[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    jobType: '',
    location: '',
    keyword: '',
  });

  useEffect(() => {
    async function loadJobs() {
      try {
        const { data, error } = await supabase
          .from('jobs')
          .select(`
            *,
            business:businesses(*)
          `)
          .eq('is_active', true)
          .order('created_at', { ascending: false });

        if (error) throw error;
        setJobs(data as JobWithBusiness[]);
      } catch (error) {
        console.error('Error loading jobs:', error);
      } finally {
        setLoading(false);
      }
    }

    loadJobs();
  }, []);

  const filteredJobs = jobs.filter(job => {
    const matchesType = !filter.jobType || job.job_type === filter.jobType;
    const matchesLocation = !filter.location || 
      job.location?.toLowerCase().includes(filter.location.toLowerCase()) ||
      job.business.suburb?.toLowerCase().includes(filter.location.toLowerCase());
    const matchesKeyword = !filter.keyword || 
      job.title.toLowerCase().includes(filter.keyword.toLowerCase()) ||
      job.description.toLowerCase().includes(filter.keyword.toLowerCase());

    return matchesType && matchesLocation && matchesKeyword;
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-700"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h1 className="text-3xl font-bold mb-6">Landscaping Jobs</h1>
        
        <div className="grid md:grid-cols-3 gap-4">
          <div>
            <label htmlFor="keyword" className="block text-sm font-medium text-gray-700 mb-1">
              Search
            </label>
            <input
              type="text"
              id="keyword"
              placeholder="Job title or keywords..."
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              value={filter.keyword}
              onChange={(e) => setFilter(prev => ({ ...prev, keyword: e.target.value }))}
            />
          </div>
          
          <div>
            <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
              Location
            </label>
            <input
              type="text"
              id="location"
              placeholder="City or suburb..."
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              value={filter.location}
              onChange={(e) => setFilter(prev => ({ ...prev, location: e.target.value }))}
            />
          </div>
          
          <div>
            <label htmlFor="jobType" className="block text-sm font-medium text-gray-700 mb-1">
              Job Type
            </label>
            <select
              id="jobType"
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              value={filter.jobType}
              onChange={(e) => setFilter(prev => ({ ...prev, jobType: e.target.value }))}
            >
              <option value="">All Types</option>
              <option value="full_time">Full Time</option>
              <option value="part_time">Part Time</option>
              <option value="contract">Contract</option>
              <option value="casual">Casual</option>
            </select>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        {filteredJobs.map((job) => (
          <div key={job.id} className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
            <div className="flex items-start justify-between mb-4">
              <div>
                <h2 className="text-xl font-semibold mb-2">{job.title}</h2>
                <div className="flex items-center text-gray-600 mb-2">
                  <Building2 className="w-4 h-4 mr-1" />
                  <span className="mr-4">{job.business.name}</span>
                  <MapPin className="w-4 h-4 mr-1" />
                  <span>{job.location || `${job.business.suburb}, ${job.business.state}`}</span>
                </div>
              </div>
              <div className="flex items-center">
                <Briefcase className="w-4 h-4 mr-1 text-green-600" />
                <span className="text-sm font-medium text-green-600">
                  {job.job_type.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </span>
              </div>
            </div>
            
            <p className="text-gray-600 mb-4 line-clamp-3">{job.description}</p>
            
            <div className="flex items-center justify-between">
              <div className="flex items-center text-gray-500 text-sm">
                <Clock className="w-4 h-4 mr-1" />
                <span>Posted {new Date(job.created_at).toLocaleDateString()}</span>
              </div>
              
              {job.salary_range_min && job.salary_range_max && (
                <div className="text-sm font-medium">
                  ${job.salary_range_min.toLocaleString()} - ${job.salary_range_max.toLocaleString()} per year
                </div>
              )}
            </div>
          </div>
        ))}

        {filteredJobs.length === 0 && (
          <div className="text-center py-12">
            <h3 className="text-xl font-semibold text-gray-600 mb-2">No jobs found</h3>
            <p className="text-gray-500">Try adjusting your search criteria</p>
          </div>
        )}
      </div>
    </div>
  );
}