import React from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import type { Tables } from '../lib/supabase';

type Business = Tables['businesses']['Row'];

type BusinessMapProps = {
  businesses: Business[];
  center?: { lat: number; lng: number };
  zoom?: number;
  onBusinessClick?: (business: Business) => void;
};

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

export default function BusinessMap({ 
  businesses,
  center = { lat: -25.2744, lng: 133.7751 }, // Center of Australia
  zoom = 4,
  onBusinessClick 
}: BusinessMapProps) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY || '',
    libraries: ['places'],
  });

  const [selectedBusiness, setSelectedBusiness] = React.useState<Business | null>(null);

  if (loadError) {
    return <div className="text-red-600">Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div className="animate-pulse bg-gray-200 h-[400px] rounded-lg"></div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={zoom}
      center={center}
      options={{
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
        ],
        mapTypeControl: false,
        streetViewControl: false,
      }}
    >
      {businesses.map((business) => {
        if (!business.latitude || !business.longitude) return null;
        
        return (
          <Marker
            key={business.id}
            position={{ lat: business.latitude, lng: business.longitude }}
            onClick={() => {
              setSelectedBusiness(business);
              if (onBusinessClick) onBusinessClick(business);
            }}
          />
        );
      })}

      {selectedBusiness && selectedBusiness.latitude && selectedBusiness.longitude && (
        <InfoWindow
          position={{ lat: selectedBusiness.latitude, lng: selectedBusiness.longitude }}
          onCloseClick={() => setSelectedBusiness(null)}
        >
          <div className="p-2">
            <h3 className="font-semibold mb-1">{selectedBusiness.name}</h3>
            <p className="text-sm text-gray-600">{selectedBusiness.suburb}, {selectedBusiness.state}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}