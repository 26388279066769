import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MapPin, Star, Phone, Globe } from 'lucide-react';
import { supabase } from '../lib/supabase';
import type { Tables } from '../lib/supabase';
import BusinessMap from '../components/BusinessMap';
import LocationSearch from '../components/LocationSearch';

type Business = Tables['businesses']['Row'];

export default function LocationPage() {
  const { state, city } = useParams<{ state: string; city: string }>();
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [loading, setLoading] = useState(true);
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number } | null>(null);

  useEffect(() => {
    async function loadBusinesses() {
      try {
        const formattedState = state?.replace(/-/g, ' ');
        const formattedCity = city?.replace(/-/g, ' ');
        
        const { data, error } = await supabase
          .from('businesses')
          .select('*')
          .eq('state', formattedState)
          .eq('suburb', formattedCity)
          .order('created_at', { ascending: false });

        if (error) throw error;
        setBusinesses(data || []);

        // Set map center to first business location or city center
        if (data && data[0] && data[0].latitude && data[0].longitude) {
          setMapCenter({ lat: data[0].latitude, lng: data[0].longitude });
        }
      } catch (error) {
        console.error('Error loading businesses:', error);
      } finally {
        setLoading(false);
      }
    }

    loadBusinesses();
  }, [state, city]);

  const handleLocationSelect = ({ lat, lng }: { lat: number; lng: number }) => {
    setMapCenter({ lat, lng });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-700"></div>
      </div>
    );
  }

  const formattedCity = city?.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
  const formattedState = state?.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase());

  return (
    <div className="space-y-8">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h1 className="text-3xl font-bold mb-4">Landscapers in {formattedCity}</h1>
        <p className="text-gray-600 mb-6">Find and compare the best landscaping professionals in {formattedCity}, {formattedState}</p>
        
        <div className="mb-6">
          <LocationSearch onSelect={handleLocationSelect} />
        </div>

        <div className="rounded-lg overflow-hidden">
          <BusinessMap
            businesses={businesses}
            center={mapCenter || undefined}
            zoom={12}
          />
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-6">
        {businesses.map((business) => (
          <div key={business.id} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow">
            <div className="h-48 bg-cover bg-center" style={{
              backgroundImage: business.cover_image_url
                ? `url(${business.cover_image_url})`
                : 'url(https://images.unsplash.com/photo-1558904541-efa843a96f01?auto=format&fit=crop&q=80)'
            }} />
            
            <div className="p-6">
              <div className="flex items-start justify-between">
                <div>
                  <h2 className="text-xl font-semibold mb-2">{business.name}</h2>
                  <div className="flex items-center text-gray-600 mb-2">
                    <MapPin className="w-4 h-4 mr-1" />
                    <span>{business.suburb}, {business.state}</span>
                  </div>
                </div>
                {business.is_verified && (
                  <div className="flex items-center text-green-600">
                    <Star className="w-5 h-5 mr-1" />
                    <span className="text-sm font-medium">Verified</span>
                  </div>
                )}
              </div>
              
              <p className="text-gray-600 mb-4 line-clamp-2">{business.description}</p>
              
              <div className="flex items-center space-x-4 text-sm text-gray-600">
                {business.phone && (
                  <div className="flex items-center">
                    <Phone className="w-4 h-4 mr-1" />
                    <span>{business.phone}</span>
                  </div>
                )}
                {business.website && (
                  <div className="flex items-center">
                    <Globe className="w-4 h-4 mr-1" />
                    <span>Website</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}

        {businesses.length === 0 && (
          <div className="md:col-span-2 text-center py-12">
            <h3 className="text-xl font-semibold text-gray-600 mb-2">No businesses found in this area</h3>
            <p className="text-gray-500">Be the first to list your landscaping business in {formattedCity}</p>
          </div>
        )}
      </div>
    </div>
  );
}