import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import ErrorBoundary from './components/ErrorBoundary';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import BusinessDirectory from './pages/BusinessDirectory';
import LocationDirectory from './pages/LocationDirectory';
import LocationPage from './pages/LocationPage';
import JobBoard from './pages/JobBoard';
import BusinessProfile from './pages/BusinessProfile';
import Dashboard from './pages/Dashboard';
import AuthCallback from './pages/AuthCallback';
import BusinessForm from './pages/BusinessForm';
import ProfileForm from './pages/ProfileForm';

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <div className="min-h-screen bg-gray-50">
          <Navbar />
          <main className="container mx-auto px-4 py-8">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/directory" element={<BusinessDirectory />} />
              <Route path="/locations" element={<LocationDirectory />} />
              <Route path="/directory/:state/:city" element={<LocationPage />} />
              <Route path="/jobs" element={<JobBoard />} />
              <Route path="/business/:slug" element={<BusinessProfile />} />
              <Route path="/dashboard/*" element={<Dashboard />} />
              <Route path="/auth/callback" element={<AuthCallback />} />
              <Route path="/dashboard/business/create" element={<BusinessForm />} />
              <Route path="/dashboard/profile" element={<ProfileForm />} />
            </Routes>
          </main>
          <Footer />
          <Toaster 
            position="top-right"
            toastOptions={{
              duration: 5000,
              error: {
                duration: 8000,
              },
            }} 
          />
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App;